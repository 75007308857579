import { extendTheme } from "@chakra-ui/react";

const theme = {
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  styles: {
    global: {
      "*": {
        scrollbarColor: "#aaa transparent",
        scrollbarWidth: "thin",
      },
      body: {
        bg: "white",
        color: "gray.800",
      },
      "::-webkit-scrollbar": {
        h: "3px",
        w: "3px",
      },
      "::-webkit-scrollbar-track": {
        bg: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        bg: "gray.400",
      },
      "a, a:hover": {
        textDecor: "none",
        transition: "all .2s",
      },
      h2: {
        fontFamily: "heading",
        fontSize: ["2rem", "3rem"],
        fontWeight: 900,
        lineHeight: 1,
        color: "darkblue",
        m: "3rem 1rem 0 0",
        textAlign: "center",
      },
      h3: {
        color: "darkblue",
        fontFamily: "heading",
        fontSize: "2rem",
        fontWeight: "black",
        lineHeight: 1,
        m: "2rem 1rem 1rem 0",
        textAlign: "center",
      },
      h4: {
        fontSize: "1.2rem",
        fontWeight: "black",
        my: 4,
      },
      hr: {
        borderStyle: "dashed",
        m: "4rem auto",
        w: "60vw",
      },
      "ul, ol": {
        fontSize: ["1rem", "1.2rem"],
        ml: "2rem",
      },
      button: {
        minHeight: 5,
      },
      footer: {
        mt: "5rem",
      },
      "p, ul": {
        color: "gray.500",
        fontSize: "1rem",
        fontWeight: "500",
        my: 4,
      },
      blockquote: {
        my: "4rem",
        textAlign: "center",
      },
      "p > a, ul li > a": {
        color: "darkblue",
      },
      "p > img": {
        borderRadius: "md",
        d: "block",
        m: "auto",
      },
      "img ~ em": {
        color: "muted",
        d: "block",
        fontSize: "md",
        p: "1rem",
        textAlign: "center",
      },
      "blockquote p": {
        color: "gray.800",
        fontSize: "1.7rem",
        fontWeight: "bold",
      },
      ".pagination a": {
        color: "darkblue",
      },
      ".pagination a:hover": {
        color: "blue",
      },
      ".pagination a.active": {
        bg: "blue",
        borderRadius: "5rem",
        color: "white",
        fontWeight: "bold",
        p: "0.5rem 1rem ",
      },
      ".inline-gatsby-image-wrapper": {
        borderRadius: "md",
        my: "1rem",
        pointerEvents: "none",
      },
      '.inline-gatsby-image-wrapper > div > a > img[role="presentation"]': {
        h: 0,
      },
      "picture img": {
        borderRadius: "md",
      },
    },
  },
  fonts: {
    body: "Work Sans",
    heading: "Work Sans",
  },
  colors: {
    gray: {
      100: "#f8f8f8",
      200: "#eee",
      300: "#ddd",
      400: "#afafaf",
      500: "#5f5f5f",
      700: "#303030",
      800: "#202020",
      900: "#121212",
    },
    blue: "#7389ae",
    brown: "#452500",
    button: "#87a878",
    darkblue: "#455776",
    darkpurple: "#9787af",
    green: "#6d905d",
    navy2: "rgba(2, 49, 69, 0.98)",
    navy: "#023145",
    orange: "#be7c4d",
    red: "#fd6389",
    shadow: "rgba(0, 0, 0, 0.2)",
  },
};

export default extendTheme(theme);
